import { useEffect, useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Col } from 'reactstrap';
import useOnClickOutside from 'hooks/use-onClick-outside';
import { useDispatch } from 'react-redux';
import { useTranslations } from 'next-intl';
import useMount from 'hooks/use-mount';
import useMemoSelector from 'hooks/useMemoSelector';

import Banner from 'Components/Banner';
import HeaderBtn from 'Components/Dumb/HeaderButton';

import { toggleCategoriesMenuMobile } from 'store/actions';
import {
  adCategories,
  baseSelector,
  getUserAccountId,
  deviceParams as deviceParamsGetters,
} from 'store/reselect';

import useStyles from './styles';

function sortCategoriesByPredefinedOrder(items) {
  const predefinedOrder = [
    'ladies_of_pleasure',
    'cam_phonesex',
    'free_sex_contact',
    'sugar_dating',
    'vacancies',
    'events',
    'erotic_massage',
    'sm',
    'men',
    'gay_bi',
    'sex_items',
    'payment_in_kind',
    'misc',
  ];

  return items.sort((a, b) => {
    const indexA = predefinedOrder.indexOf(a.title);
    const indexB = predefinedOrder.indexOf(b.title);
    return indexA - indexB;
  });
}

function sortLabelsAlphabetically(t, items) {
  return items
    .map(item => ({ ...item, translatedTitle: t(item.title) }))
    .sort((a, b) => a.translatedTitle.localeCompare(b.translatedTitle))
    .map(({ translatedTitle, ...rest }) => rest);
}

const List = props => {
  const t = useTranslations();
  const activeCategoryPath = null;
  const [activeItemMobile, setActiveItemMobile] = useState(activeCategoryPath);
  const [activeCategoryName, setActiveCategoryName] = useState('');

  const slideDeep = item => {
    if (props.isMobile && props.toggleDeep) {
      setActiveItemMobile(item.pathname);
      setActiveCategoryName(item.title);
      props.toggleDeep(true);
    }
  };

  let items;

  const isSugarDating = props.data.some(
    item => item.parentSlug === 'sugar-dating',
  );

  if (props.isDeep === false) {
    items = sortCategoriesByPredefinedOrder(props.data);
  } else if (isSugarDating) {
    items = props.data;
  } else {
    items = sortLabelsAlphabetically(t, props.data);
  }

  return (
    <ul
      type="none"
      className={`${props.labels ? 'sub-list_menu' : ''} ${
        !props.labels && props.isDeep ? 'slide-to-left' : ''
      } ${props.className}`}
    >
      {items
        .filter(item => item.pathname === 'back-item')
        .concat(items.filter(item => item.pathname !== 'back-item'))
        .map((item, index) => {
          const pathname = item.parentSlug
            ? `/${item.parentSlug}/${item.value_nl}`
            : `/${item.value_nl}`;

          return item.pathname === 'back-item' ? (
            <li key={index} className="back-to-parent_category">
              {t(item.title)}
            </li>
          ) : (
            <li
              key={index}
              className={`${
                !props.labels && activeItemMobile === item.pathname
                  ? 'active-mobile-item'
                  : ''
              } list-item--li`}
            >
              <Link href={pathname}>
                <a>
                  <span
                    className="list-item--a"
                    onClick={props.closeBeforeRouting}
                  >
                    {t(item.title)}
                  </span>
                </a>
              </Link>
              {!!item.labels && (
                <>
                  <span
                    className="icon-Chevron---Right arrow_icon_list-item"
                    onClick={() => slideDeep(item)}
                  />
                  <List
                    data={[
                      ...(activeCategoryName && [
                        { title: activeCategoryName, pathname: 'back-item' },
                      ]),
                      ...item.labels,
                    ]}
                    closeBeforeRouting={props.closeBeforeRouting}
                    labels
                    className="list-menu--ul"
                  />
                </>
              )}
            </li>
          );
        })}
    </ul>
  );
};

List.defaultProps = {
  className: PropTypes.string,
  closeBeforeRouting: () => void 0,
};

List.propTypes = {
  closeBeforeRouting: PropTypes.func,
  isMobile: PropTypes.bool,
  isDeep: PropTypes.bool,
  toggleDeep: PropTypes.func,
  data: PropTypes.array,
  labels: PropTypes.bool,
};

const AsideMenu = () => {
  const dispatch = useDispatch();

  const { userId, categoryList, categoriesMobileToggle, deviceParams } =
    useMemoSelector(store => ({
      userId: getUserAccountId(store),
      categoryList: adCategories(store),
      categoriesMobileToggle: baseSelector()(store)?.categoriesMobileToggle,
      deviceParams: deviceParamsGetters()(store),
    }));

  const [isDeep, setIsDeep] = useState(false);
  const asideRef = useRef();
  const mobileTopPanelRef = useRef();
  const { deviceType } = deviceParams;

  const toggleDeep = val => {
    setIsDeep(val);
  };

  function closeAsideMenu() {
    setTimeout(() => {
      toggleDeep(false);
    }, 400); // Should wait until the sideBar menu will gone to disappear
    dispatch(toggleCategoriesMenuMobile(false));
  }

  useOnClickOutside(asideRef, () => {
    if (categoriesMobileToggle && deviceType === 'mobile') {
      closeAsideMenu();
    }
  });

  const styles = useStyles();

  useEffect(() => {
    const isSSR = typeof window !== 'undefined';
    if (isSSR && deviceType === 'mobile') {
      document.body.classList[categoriesMobileToggle ? 'add' : 'remove'](
        'aside-menu-is-open',
      );
    }
  }, [categoriesMobileToggle, deviceType]);

  useMount(() => {
    return () => {
      dispatch(toggleCategoriesMenuMobile(false));

      if (deviceType === 'mobile') {
        document.body.classList.remove('aside-menu-is-open');
      }
    };
  });

  return (
    <Col xs={12} md={4} lg={3} xl={2}>
      <aside
        className={`${styles['aside-menu-bar']} ${
          categoriesMobileToggle ? 'open-mobile-categories_menu' : ''
        }`}
      >
        <div ref={asideRef} className={styles['aside-menu-bar--child-fr']}>
          {deviceType === 'mobile' ? (
            <div ref={mobileTopPanelRef} className="mobile-top-panel">
              {isDeep ? (
                <p
                  onClick={() => setIsDeep(false)}
                  className="mobile-top-panel--p"
                >
                  <span className="icon-Chevron---Left icon-back-arrow"></span>
                  All categories
                </p>
              ) : (
                <>
                  <span
                    className="icon-Close close-menu_icon"
                    onClick={() => closeAsideMenu()}
                  ></span>
                  {/* eslint-disable-next-line react/jsx-no-bind */}
                  <HeaderBtn
                    isLoggedIn={!!userId}
                    // eslint-disable-next-line react/jsx-no-bind
                    closeAsideMenu={closeAsideMenu}
                  />
                </>
              )}
            </div>
          ) : (
            ''
          )}
          <List
            dispatch={dispatch}
            className="parent-list--ul list-menu--ul"
            data={categoryList}
            isMobile={deviceType === 'mobile'}
            toggleDeep={toggleDeep}
            isDeep={isDeep}
            closeBeforeRouting={() =>
              dispatch(toggleCategoriesMenuMobile(false))
            }
          />
          {deviceType === 'mobile' && !userId ? (
            <div className="login-creat-account_wrapper">
              <Link href="/login">
                <span className="action-button">
                  <span className="icon-My-Account"></span>Login
                </span>
              </Link>
              <Link href="/register">
                <span className="action-button">
                  <span className="icon-Add-account"></span>Create Account
                </span>
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </aside>
      {deviceType !== 'mobile' && <Banner size="160x600" />}
    </Col>
  );
};

export default memo(AsideMenu);
